<template>
  <div class="edrea_tm_section" id="news">
    <div class="section_inner">
      <div class="edrea_tm_news swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Serviços <span class="coloring">Exclusivos</span></h3>
        </div>
        <div class="news_list">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(data, i) in newsData"
              :key="i"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-3.jpg" alt="" />
                  <div class="main" :data-img-url="data.img"></div>
                  <a class="edrea_tm_full_link news_popup" href="#"></a>
                </div>
                <div class="details">
                  <h3>
                    <a href="#">{{ data.title }}</a>
                  </h3>
                  <span
                    ><a href="#">{{ data.tag }}</a></span
                  >
                </div>

                <!-- News Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="news_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" :data-img-url="data.img"></div>
                    </div>
                    <div class="news_main_title">
                      <h3>{{ data.title }}</h3>
                      <span
                        ><a href="#">{{ data.tag }}</a></span
                      >
                      <div></div>
                    </div>
                    <div class="text">
                      <p v-for="(text, t) in data.des" :key="t">
                        {{ text }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /News Popup Informations -->
              </div>
            </swiper-slide>
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="currentNews">0</span>
                <span class="pagination_progress"
                  ><span class="all allNews"><span></span></span
                ></span>
                <span class="totalNews">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `NewsComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(
            current,
            total,
            "currentNews",
            "totalNews",
            "allNews"
          );
        },
      },
    };
  },
  data() {
    return {
      newsData: [
        {
          title: "Comunidade Privada",
          img: "img/news/1.jpg",
          tag: "Networking",
          des: [
            "Você é um reflexo das pessoas com quem passa mais tempo. Você precisa se cercar de pessoas de sucesso, pessoas com quem possa aprender.",
            "Como resultado de nossos anos de experiência em nossa área, tivemos o privilégio de construir relacionamentos com algumas das pessoas mais respeitadas e influentes em várias indústrias.",
            "Essas conexões nos permitiram estabelecer uma rede privada robusta, a Comunidade Oriqy é composta por indivíduos talentosos, respeitados e de mentalidade ambiciosa.",
            "Nossa rede privada serve como um recurso valioso para colaboração e apoio, à medida que nossos clientes buscam alcançar novos patamares e expandir seu potencial.",
            "Reconhecemos a importância de se conectar com indivíduos estabelecidos em sua indústria e temos orgulho de oferecer esse recurso aos nossos clientes."
          ],
        },
        {
          title: "Gestão de Marca",
          img: "img/news/2.jpg",
          tag: "Consultoria",
          des: [
            "Entendemos que uma marca é mais do que apenas um logotipo ou esquema de cores; ela abrange a percepção geral do seu negócio na mente do seu público.",
            "Nossa equipe de especialistas em branding colaborará com você para compreender o seu negócio, público-alvo e objetivos.",
            "Com base nessas informações, desenvolveremos uma estratégia de branding que ressoa efetivamente com o seu público e posiciona a sua marca para o sucesso dentro do seu setor.",
          ],
        },
        {
          title: "Gestão de Trafego",
          img: "img/news/3.jpg",
          tag: "Assessoria",
          des: [
            "Nossa assessoria privada te conduz durante todas as etapas da gestão de trafego, incluindo: planejamento, execução e contingencia da campanha. E ao fim do serivços fornecemos os relatórios de performance com todas as informações necessárias.",
          ],
        },
        {
          title: "Desenvolvimento de Software",
          img: "img/news/4.jpg",
          tag: "Programação",
          des: [
            "Oferecemos uma ampla gama de serviços de desenvolvimento de software para ajudar nossos clientes a criar soluções inovadoras e eficazes.",
            "Nossa equipe de profissionais experientes possui as habilidades e o conhecimento para concretizar suas ideias, independentemente da complexidade ou ambição delas.",
          ],
        },
      ],
    };
  },
};
</script>
