<template>
  <div class="edrea_tm_modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/about/1.png"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>                
                  <!-- <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span
                        ><a href="#" class="href_location">{{
                          aboutData.address
                        }}</a></span
                      >
                    </div>
                  </li>    -->
                  <li>
                    <div class="list_inner">
                      <i class="icon-mail-1"></i>
                      <span
                        ><a :href="`mailto:${aboutData.email}`">{{
                          aboutData.email
                        }}</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <div class="edrea_tm_button full">
                <a href="img/about/1.jpg" download>Download CV</a>
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <!-- <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>Sobre <span class="coloring">Nós</span></span>
                  </h3>
                </div>
                <div class="text">
                  <p>
                    Hello everybody! My name is
                    <span>David Parker.</span> I am a graphic designer, and I'm
                    very passionate and dedicated to my work. With 20 years
                    experience as a professional a graphic designer, I have
                    acquired the skills and knowledge.
                  </p>
                </div>
              </div> -->
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>Serviços <span class="coloring">Especializados</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span
                      >Tecnologias <span class="coloring">Dominadas</span></span
                    >
                  </h3>
                </div>
                <div class="oki_progress">
                  <div
                    class="progress_inner"
                    v-for="(skill, i) in aboutData.skills.programming"
                    :key="i"
                  >
                    <span
                      ><span class="label">{{ skill.name }}</span
                      ><span class="number">{{ skill.value }}%</span></span
                    >
                    <div class="background">
                      <div class="bar open">
                        <div
                          class="bar_in"
                          :style="{ width: `${skill.value}%` }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              

              <!-- <div class="lang_skill">
                <div class="about_title">
                  <h3>
                    <span>Mercados em que temos <span class="coloring">experiencia extensa</span></span>
                  </h3>
                </div>
                <div class="circular_progress_bar">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1"></div>

                        <div class="title"><span>America do Sul e Norte</span></div>
                        <div><span class="coloring">Expertise consolidada com mais de 5 anos de operações bem-sucedidos.</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1"></div>

                        <div class="title"><span>Europa</span></div>
                        <div><span class="coloring">Profundo conhecimento de mercado adquirido através de parcerias estratégicas e [Z iniciativas] implementadas com êxito.</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.6"></div>

                        <div class="title"><span>Asia</span></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>      -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Oriqy",
        lastName: "Agency",
        bithday: "01.07.1990",
        address: "Nova Lima, Minas Gerais.",
        phn: "+77 022 155 02 02",
        email: "contato@oriqy.com",
        serviceLists: [          
          "SEO",
          "iGaming",
          "E-commerce",
          "Gestão de tráfego",
          "Social Media Marketing",          
          "Desenvolvimento de Software",          
          "Gestão e desenvolvimento de marca",
          
        ],
        skills: {
          programming: [
            { name: "WordPress", value: "100" },
            { name: "JavaScript", value: "100" },
            { name: "Python", value: "100" },      
          ],
          language: [
            { name: "Português", value: "100" },
            { name: "English", value: "95" },
            { name: "日本語", value: "80" },
          ],
          trading: [
            { name: "Opções", value: "100" },            
            { name: "Crypto", value: "100" },
            { name: "Juros", value: "80" },
          ],
        },
        // education: [
        //   {
        //     year: "2014 - 2016",
        //     unv: "Oxford Univercity",
        //     degree: "Master Degree",
        //   },
        //   {
        //     year: "2010 - 2014",
        //     unv: "Texas Univercity",
        //     degree: "Bachelor Degree",
        //   },
        //   {
        //     year: "2008 - 2010",
        //     unv: "Simone College",
        //     degree: "Associate Degree",
        //   },
        // ],
        // working: [
        //   {
        //     year: "2018 - running",
        //     company: "Envato Elements",
        //     deg: "Exclusive Author",
        //   },
        //   {
        //     year: "2015 - 2018",
        //     company: "Avo Corporation",
        //     deg: "Content Manager",
        //   },
        //   {
        //     year: "2012 - 2015",
        //     company: "FC Barcelona",
        //     deg: "Football Player",
        //   },
        // ],
        // partnersLogos: [
        //   "img/partners/1.png",
        //   "img/partners/2.png",
        //   "img/partners/3.png",
        //   "img/partners/4.png",
        // ],
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}
.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}
.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(
    var(--main-color) var(--progress, 0%),
    rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%
  );

  mask-image: radial-gradient(
    transparent var(--holesize),
    black calc(var(--holesize) + 1px)
  );
}
</style>
